import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";
let is_mapbox_contact = document.getElementById("mapbox_contact");
if (is_mapbox_contact) {
  // var mapboxgl = require("mapbox-gl/dist/mapbox-gl.js");

  mapboxgl.accessToken =
    "pk.eyJ1IjoiYmFuY21hcHMiLCJhIjoiY2szc3NoMGVuMDhtNTNtbGZobnp1Y3EzOSJ9.gIIFW7f1aqL1Ac5A0Qbl7A";
  var map = new mapboxgl.Map({
    container: "mapbox_contact",
    style: "mapbox://styles/mapbox/light-v11",
    center: [-0.1465669, 51.5168702],
    zoom: 13,
  });
  const geojson = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [-0.1465669, 51.5168702],
        },
        properties: {
          title: "Mapbox",
          description: "kingston estates",
        },
      },
    ],
  };
  // add markers to map
  for (const feature of geojson.features) {
    // create a HTML element for each feature
    const el = document.createElement("div");
    el.className = "marker";

    // make a marker for each feature and add to the map
    new mapboxgl.Marker({ color: "#06676F", rotation: 0 })
      .setLngLat(feature.geometry.coordinates)
      .addTo(map);
  }
}
