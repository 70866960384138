//THEME
import defaults from "./custom/defaults.js";
import navbar from "./custom/navbar.js";
import footer from "./custom/footer.js";
import mapbox_contact from "./custom/mapbox_contact.js";

//EXTRAS
//import cards from './custom/cards.js';
//import progressBar from './custom/progressBar.js';
//import annimate from './custom/annimate.js';

//VueJS
//import footer from './custom/vue-scripts.js';
